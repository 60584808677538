/*
 * @Author: qiankun
 * @LastEditors: fangjun
 */
import { login, logout, getUserInfo, getCustomInfo } from '@/api/login'
import { defineStore } from 'pinia'
import { setToken, getToken, setExpiresIn, getExpiresIn, clearToken } from '@/utils/auth'
import defaultAvatar from '@/assets/images/avatar_default.png'
export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    token: getToken(),
    expires_in: getExpiresIn(),
    name: '',
    avatar: '',
    roleId: null,
    permissions: [],
    roles: [],
    user: {},
    currentCustomAreaId: '',
    customArea: {
      id: '',
      name: '',
      corporateName: '',
      address: '',
      industryType: '',
      scene: '',
      longitude: '',
      latitude: ''
    },
    customAreaList: []
  }),
  actions: {
    SET_USER_INFO(res) {
      let { permissions, user, roles } = res
      this.avatar = user.avatar ? user.avatar : defaultAvatar
      if (user.roleId !== null) {
        // 验证返回的roles是否是一个非空数组
        this.roleId = user.roleId
        this.permissions = permissions
        this.roles = roles
      }
      this.name = user.userName
      this.user = user
    },
    SET_CUSTOMAREA_INFO(res) {
      let { data } = res
      this.customArea = {
        ...this.customArea,
        ...data
      }
      // this.customArea.id = data.id
      // this.customArea.name = data.name
      // this.customArea.corporateName = data.corporateName
      // this.customArea.address = data.address
      // this.customArea.industryType = data.industryType
      // this.customArea.scene = data.scene
      // this.customArea.longitude = data.longitude
      // this.customArea.latitude = data.latitude
    },
    login(params) {
      return login(params).then((res) => {
        let { data } = res
        this.token = data.access_token
        this.expires_in = data.expires_in
        setToken(data.access_token)
        setExpiresIn(data.expires_in)
      })
    },
    logout() {
      return new Promise((resolve, reject) => {
        logout(this.token)
          .then(() => {
            this.token = ''
            this.roleId = null
            this.permissions = []
            this.roles = []
            clearToken()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getUserInfo() {
      return getUserInfo().then((res) => {
        this.SET_USER_INFO(res)
      })
    },
    getCustomAreaInfo() {
      return getCustomInfo().then((res) => {
        this.SET_CUSTOMAREA_INFO(res)
      })
    },
    getUserInfoAndCustomAreaInfo() {
      let useInfo = getUserInfo()
      let customAreaInfo = getCustomInfo()
      return Promise.all([useInfo, customAreaInfo]).then((resArr) => {
        let [userInfo, customAreaInfo] = resArr
        this.SET_USER_INFO(userInfo)
        this.SET_CUSTOMAREA_INFO(customAreaInfo)
      })
    },
    setCustomArea(id) {
      this.currentCustomAreaId = id
    },
    setCustomAreaList(list) {
      this.customAreaList = list
    }
  }
})
